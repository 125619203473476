/* Heading Styles */

/* src/WhatsInside.css */
:root {
    --transition-time: 2s;
}

.whats-inside-heading {
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    font-family: Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-top: 20px;
    /* Adjust margin-top as needed */
    margin-bottom: 70px;
    width: 100%;
    opacity: 0; /* Initially hide the heading */
    transition: opacity var(--transition-time) ease-in-out;
}
.whats-inside-heading.appear {
    opacity: 1; /* Make the heading visible */
}


@media (max-width: 768px) {
    .whats-inside-heading {
        font-size: 2.5rem;
    }
    .whats-inside-paragraph {
        font-size: 1.5rem;
        font-family: Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        text-align: left;
        margin-left: -30px;
    }
}

.whats-inside-section {
    display: flex;
    flex-direction: column;
    /* Stack the heading and content vertically */
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    text-align: left;
    /* Align text to the left */
    height: 100vh;
    /* Ensure the section takes up full viewport height */
    position: relative;
    width: 100%;
    height: 100%
}

.whats-inside-content {
    display: flex;
    flex-direction: row;
    /* Stack paragraph and paper horizontally */
    justify-content: space-between;
    align-items: center;
    width: 85%;
    opacity: 0; /* Initially hide the content */
    transition: opacity var(--transition-time) ease-in-out; 
}

.whats-inside-content.appear {
    opacity: 1; /* Make the content visible */
}

/* Container for the paragraph text */
.whats-inside-text {
    flex: 1;
    font-size: 1.2rem;
    padding: 20px;
    max-width: 40%;
    box-sizing: border-box;
    margin-right: 20px;
    /* Space between text and paper */
}

/* Fixed aspect ratio for the ruled paper */
.ruled-paper {
    position: relative;
    width: 80%;
    max-width: 600px;
    aspect-ratio: 1 / 1.11;
    /* Fixed aspect ratio */
    background-color: #fff;
    /* Paper color */
    border: 1px solid #000000;
    /* Border color */
    padding: 20px;
    box-shadow: 0 4px 6px rgba(120, 120, 120, 0.1);
    overflow: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-in-out;
}

.ruled-paper.appear {
    opacity: 1;
    transform: translateY(0);
}

.ruled-paper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: repeating-linear-gradient(to bottom,
            #000 0,
            #000 1px,
            #fff 1px,
            #fff 40px
            /* Adjust line thickness and spacing */
        );
    opacity: 0.1;
    /* Line opacity */
}

/* Control the ruled paper color, line thickness, and text height */
:root {
    --paper-color: #f5f4d4;
    --line-color: #000;
    --line-thickness: 1px;
    --line-spacing: 40px;
    --text-height: 1.2rem;
}

.ruled-paper {
    background-color: var(--paper-color);
}

.ruled-paper::before {
    background: repeating-linear-gradient(to bottom,
            var(--line-color) 0,
            var(--line-color) var(--line-thickness),
            var(--paper-color) var(--line-thickness),
            var(--paper-color) var(--line-spacing));
}

/* Typewriter Effect */
.typewriter-text {
    font-size: 1.5rem;
    font-family: 'Kalam';
    white-space: pre-wrap;
    line-height: 40px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    height: 100%;
    top: -2%;
}

/* Typewriter Cursor */
.cursor {
    display: inline-block;
    width: 2px;
    height: 1.2rem;
    background-color: rgb(0, 0, 0);
    animation: blink 1s step-end infinite;
    vertical-align: middle;
    margin-left: 2px;
}

@keyframes blink {

    from,
    to {
        background-color: transparent;
    }

    50% {
        background-color: rgb(0, 0, 0);
    }
}

@media (max-width: 768px) {
    .whats-inside-heading {
        font-family: Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 2.5rem;
        font-weight: bold;
    }

    .whats-inside-content {
        flex-direction: column;
        /* Stack content vertically on mobile */
        align-items: center;
    }

    .whats-inside-text {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        /* Space between text and paper */
    }

    /* Typewriter Effect */
    .typewriter-text {
        font-size: 1.5rem;
        font-family: 'Kalam';
        white-space: pre-wrap;
        line-height: 25px;
        overflow: hidden;
        position: relative;
        display: inline-block;
        height: 100%;
        top: -4%;
    }

    /* Fixed aspect ratio for the ruled paper */
    .ruled-paper {
        position: relative;
        width: 120%;
        max-width: 650px;
        height: 500px;
        /* aspect-ratio: 1 / 1.11; */
        /* Fixed aspect ratio */
        background-color: #fff;
        /* Paper color */
        border: 1px solid #000000;
        /* Border color */
        padding: 20px;
        box-shadow: 0 4px 6px rgba(120, 120, 120, 0.1);
        overflow: hidden;
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 1s ease-in-out;
    }

    .ruled-paper.appear {
        opacity: 1;
        transform: translateY(0);
    }

    .ruled-paper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: repeating-linear-gradient(to bottom,
                #000 0,
                #000 1px,
                #fff 1px,
                #fff 40px
                /* Adjust line thickness and spacing */
            );
        opacity: 0.1;
        /* Line opacity */
    }

    /* Control the ruled paper color, line thickness, and text height */
    :root {
        --paper-color: #f5f4d4;
        --line-color: #000;
        --line-thickness: 1px;
        --line-spacing: 25px;
        --text-height: 1.2rem;
    }

    .ruled-paper {
        background-color: var(--paper-color);
    }

    .ruled-paper::before {
        background: repeating-linear-gradient(to bottom,
                var(--line-color) 0,
                var(--line-color) var(--line-thickness),
                var(--paper-color) var(--line-thickness),
                var(--paper-color) var(--line-spacing));
    }
}