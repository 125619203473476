body {
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* General styling for the body text */
.body {
  font-size: 2rem;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-top: 20px;
  text-align: center;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* General styling for the heading */
.body-heading {
  margin-top: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* Styling for the images */
.card-box img {
  border-radius: 20px;
  width: 100%;
  height: auto;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* Reveal animation for elements when they appear */
.card-box.appear .body,
.card-box.appear .body-heading,
.card-box.appear img {
  opacity: 1;
  transform: translateY(0);
}

/* Container with CSS Grid layout */
.card-box {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px; /* Space between columns */
  align-items: center;
  margin-bottom: 30px;
}

/* Align content text to the left */
.card-box .content {
  text-align: left;
}

/* Ensure images are responsive */
.card-box img {
  max-width: 100%;
}

/* Mobile view adjustments */
@media (max-width: 767px) {
  .card-box {
    grid-template-columns: 1fr; /* Single column for mobile */
    grid-template-areas:
      "heading"
      "image"
      "text"; /* Define the order of items for mobile */
    text-align: center;
  }

  .card-box .content {
    grid-area: heading; /* Assign area for heading */
    text-align: center;
  }

  .card-box img {
    grid-area: image; /* Assign area for image */
  }

  .card-box .body {
    grid-area: text; /* Assign area for text */
    text-align: center;
  }
}

/* Desktop view adjustments */
@media (min-width: 768px) {
  .body {
    font-size: 2rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-top: -260px;
    text-align: center;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
  .body-heading {
  margin-top: 60px;
}

  
  .card-box:nth-child(odd) {
    grid-template-areas:
      "image heading"
      "image text"; /* Odd cards: image on the left, heading and text on the right */
  }

  .card-box:nth-child(even) {
    grid-template-areas:
      "heading image"
      "text image"; /* Even cards: heading and text on the left, image on the right */
  }

  .card-box img {
    grid-area: image; /* Assign area for image */
  }

  .card-box .content {
    grid-area: heading; /* Assign area for heading */
  }

  .card-box .body {
    grid-area: text; /* Assign area for text */
  }
}