/* CustomDropdown.css */

.custom-dropdown {
  position: relative;
  margin-bottom: 20px;
}

.custom-dropdown label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.dropdown-header {
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-header.open {
  border-bottom-color: #007bff;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  margin-top: 5px;
}

.dropdown-list-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.dropdown-list-item:hover {
  background: #007bff;
  color: white;
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s;
}

.dropdown-header.open .arrow {
  transform: rotate(180deg);
}
