.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.5px);
  background-color: rgba(0, 0, 0, 0.25); /* Add this line for black overlay with 50% opacity */
  z-index: 1;
}

.home-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('./Images/Heroimage.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}

.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.5px);
  z-index: 1;
}

.home-text {
  color: rgb(255, 255, 255);
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  z-index: 2; /* Ensure hero text is above the blur effect */
}

.typewriter-heroimage {
  font-size: 50px;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word; /* Allows text to wrap within the container */
  width: 100%; /* Ensures the text container takes the full width */
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.cta-button.sticky {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 10px 30px;
  font-size: 1.2rem;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  z-index: 10;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .typewriter-heroimage {
    font-size: 2.5rem;
    white-space: normal; /* Allows text to wrap within the container */
    overflow: hidden;
    word-wrap: break-word; /* Allows text to wrap within the container */
    width: 100%; /* Ensures the text container takes the full width */
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .typewriter {
    font-size: 2.5rem;
    white-space: normal; /* Ensures text wraps within the container */
  }

  .cta-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .cta-button.sticky {
    padding: 12px 24px;
    font-size: 1rem;
  }
}
