.image-carousel {
  /* padding: 50px 20px; */
  background-color: #fff;
}

.image-carousel-heading {
  margin-bottom: 100px;
  font-size: 50px;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #000;
  text-align: center;
  font-weight: bold;
  opacity: 0; /* Initially hide the heading */
  transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.image-carousel-heading.appear {
  opacity: 1; /* Make the heading visible */
}

.image-carousel-slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
  overflow-x: auto;  /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.image-carousel-slider-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers */
}

.image-carousel-slider-container.visible {
  opacity: 1; /* Make the content visible */
}

.image-carousel-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  gap: 20px; /* Add gap between the cards */
}

.image-carousel-slide {
  position: relative;
  flex: 0 0 calc(100% - 20px); /* Ensure at least one full card is visible on mobile */
  box-sizing: border-box;
  height: 75vh; /* Set the height to 75% of the viewport height */
  width: auto; /* Ensure width is auto to maintain the aspect ratio */
  opacity: 0;
  transform: translateY(20px); /* Initial offset for appearing effect */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.image-carousel-slide.active {
  opacity: 1;
  transform: translateY(0); /* Bring to original position */
}

.image-carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  border-radius: 20px;
}

.image-carousel-title {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 2rem;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  z-index: 2;
}

.image-carousel-info-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  outline: none; /* Remove default outline */
  z-index: 3;
}

.image-carousel-info-button:focus,
.image-carousel-info-button:active {
  outline: none; /* Remove focus and active outline */
}

.image-carousel-info-button:hover {
  background-color: #ffffff00;
}

.image-carousel-plus-icon {
  font-size: 1rem;
  border-radius: 25%;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.image-carousel-arrow-container {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.image-carousel-slider-arrow {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #007bff;
  margin: 0 5px;
  padding: 10px; /* Add padding for better click area */
  border-radius: 50%; /* Make it circular */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.image-carousel-slider-arrow:hover {
  background-color: rgba(0, 0, 0, 0.2); /* Darken background on hover */
}

.image-carousel-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: auto; /* Ensure the modal itself can be scrolled */
}

.image-carousel-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 80%; /* Set a maximum height for the modal content */
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
}
 .card-heading{
    text-align: left;
    font-size: 2rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight:bold;
 }

.image-carousel-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  outline: none; /* Remove default outline */
}

.image-carousel-close-button:focus,
.image-carousel-close-button:active {
  outline: none; /* Remove focus and active outline */
}

.image-carousel-close-button:hover {
  background-color: #ffffff;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .image-carousel-slider {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Make sliding smoother */
  }

  .image-carousel-slide {
    flex: 0 0 calc(100% / 3.5 - 20px); /* Ensure 3.5 cards are visible on desktop */
    height: 75vh; /* Set card height to 75% of the viewport height */
  }

  .image-carousel-arrow-container {
    display: flex; /* Show arrows on desktop */
  }

  .image-carousel-heading {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .image-carousel-heading {
    margin-bottom: 80px;
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000;
  }

  .image-carousel-slider {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Make sliding smoother */
  }

  .image-carousel-slide {
    flex: 0 0 calc(100% / 1.15 - 20px); 
    height: 70vh; /* Set card height to 75% of the viewport height */
  }

  .image-carousel-arrow-container {
    justify-content: right;
    margin-top: 20px;
  }
}
/* Add blue overlay to specific card */
.image-carousel-slide.card1-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: rgba(98, 131, 145, 0.3);
  pointer-events: none;
  z-index: 1;
}
.image-carousel-slide.card2-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: hsla(113, 100%, 25%, 0.3); /* Slightly blue color */
  pointer-events: none;
  z-index: 1;
}
.image-carousel-slide.card3-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: hsla(215, 100%, 37%, 0.3); 
  pointer-events: none;
  z-index: 1;
}
.image-carousel-slide.card4-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  /* background: hsla(113, 100%, 25%, 0.3);  */
  pointer-events: none;
  z-index: 1;
}

.image-carousel-slide.card5-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: hsla(344, 100%, 35%, 0.3); /* Slightly blue color */
  pointer-events: none;
  z-index: 1;
}

/* Modal layout adjustments */
.image-carousel-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1080px; /* Increase max width for better display on larger screens */
  max-height: 80%; /* Set a maximum height for the modal content */
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
}

.modal-content {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 25px
}

.modal-content .modal-image {
  border-radius: 20px;
}

@media (min-width: 768px) {
  .modal-content {
    flex-direction: row;
    margin-top: 30px
  }

  .modal-content p {
    flex: 1;
    margin-right: 20px;
    margin-top: 100px;
    font-size: 1.5rem;
  }

  .modal-content .modal-image {
    flex: 1;
    border-radius: 20px;
    width: 40%; /* Ensure the image takes the available width */
  }
}