.card {
  background: rgba(83, 83, 83, 0.62);
  border-radius: 20px;
  margin: auto;
  max-width: 600px;
  /* Adjust the width as needed */
}

.card-body {
  text-align: center;
  /* Center align the text */
  color: #f8f9fa;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.bedroom{
  font-size: 1.2rem;
  font-weight: normal;
}

.plot {
  font-size: 1.2rem;
  font-weight: bold;
}

.plot-area {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.sticky-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 7px 20px;
  font-size: 1.2rem;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  z-index: 10;
}

@media (max-width: 768px) {
  .cta-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .cta-button.sticky {
    padding: 12px 24px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .cta-button {
    padding: 6px 12px;
    font-size: 1rem;
  }

  .cta-button.sticky {
    padding: 10px 20px;
    font-size: 1rem;
  }
}