.pricing-section {
    padding: 50px 20px;
}

.pricing-heading {
    margin-bottom: 100px;
    font-size: 50px;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.pricing-heading.appear {
    opacity: 1; /* Make the heading visible */
}

.pricing-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0; /* Initially hide the content */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.pricing-cards-container.visible {
    opacity: 1; /* Make the content visible */
}

.pricing-card-wrapper {
    position: relative;
}

.pricing-card {
    background-color: #ffffff;
    padding: 20px;
    margin: 10px;
    width: 250px;
    transition: transform 0.3s ease-in-out;
}

.pricing-card:hover {
    transform: scale(1.05);
}

.pricing-card-wrapper::after {
    content: '';
    position: absolute;
    background-color: #ddd;
}

/* Vertical line for desktop */
@media (min-width: 768px) {
    .pricing-card-wrapper {
        margin-right: 30px;
    }

    .pricing-card-wrapper:not(:last-child)::after {
        height: 40%;
        width: 2px;
        right: 0px;
        top: 140px;
    }
}

/* Horizontal line for mobile */
@media (max-width: 767px) {
    .pricing-card-wrapper:not(:last-child)::after {
        width: 40%;
        height: 2px;
        bottom: -10px;
        left: 80px;
    }

    .pricing-section h2 {
        margin-bottom: 80px;
        font-size: 2.5rem;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
    
    .pricing-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        margin: 20px 0;
    }
}

.pricing-card-header {
    background-color: #007bff; /* Change this color based on the card type */
    color: rgb(0, 0, 0);
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.pricing-card-content {
    padding: 20px 10px;
    text-align: left;
}

.pricing-card-content ul {
    list-style-type: none;
    padding: 0;
}

.pricing-card-content ul li {
    margin: 10px 0;
    font-size: 1.2rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
}

.pricing-card-content ul li::before {
    content: "•";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.pricing-card-price {
    margin-top: 20px;
}

.pricing-card-price .slashed-price {
    font-size: 19px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-decoration: line-through;
    color: red;
}

.pricing-card-price .actual-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: hsl(0, 0%, 0%); /* Change this color based on the card type */
}

.pricing-card-footer {
    background-color: #007bff; /* Change this color based on the card type */
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}
