/* Container for the entire footer */
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px;
    background-color: #ffffff;
    border-top: 1px solid #ffffff;
    position: relative;
    margin-top: 130px;
    /* height: 400px; */
    /* Adjust height as needed */
}

/* Main text on the left */
.footer-main-text {
    font-size: 13.5rem;
    /* Adjust font size as needed */
    font-weight: bold;
    flex: 2;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.footer-text-line {
    margin-top: 10px;
    line-height: .85;
    /* Ensure no extra space between lines */
}

/* Social icons container */
.footer-social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /* Align items to the bottom */
    flex: 1;
    height: 20%;
    /* Ensure it takes full height of the container */
}

.footer-social-icons a {
    color: #000;
    font-size: 2.5rem;
    /* Adjust icon size as needed */
    margin: 5px 0;
    /* Adjust spacing between icons */
}

/* List of URLs */
.footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;
}

.footer-list li {
    margin-bottom: 10px;
    /* Adjust spacing between list items */
}

.footer-list a {
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    /* Adjust font size as needed */
}

.footer-list a:hover {
    text-decoration: underline;
}

/* Copyright text at the bottom right */
.footer-copyright {
    position: absolute;
    bottom: 23px;
    right: 70px;
    font-size: 1rem;
    /* Adjust font size as needed */
    color: #000;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column-reverse;
        align-items: flex-start;
        height: auto;
        /* Adjust height for mobile */
    }

    .footer-social-icons a {
        color: #000;
        font-size: 1.5rem;
        /* Adjust icon size as needed */
        margin: 5px 0;
        /* Adjust spacing between icons */
    }

    /* Main text on the left */
    .footer-main-text {
        font-size: 5.5rem;
        /* Adjust font size as needed */
        font-weight: bold;
        flex: 2;
        text-align: left;
        display: flex;
        flex-direction: column;
    }


    .footer-main-text {
        /* font-size: 2rem; */
        /* Adjust font size for mobile */
        margin-bottom: 20px;
    }

    .footer-social-icons {
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .footer-social-icons a {
        margin: 0 10px;
        /* Adjust spacing between icons for mobile */
    }

    .footer-list {
        margin-bottom: 20px;
    }

    .footer-copyright {
        bottom: 20px;
        right: 20px;
        font-size: 0.8rem;
        /* Adjust font size for mobile */
    }
}