.gallery-section {
    padding: 50px 20px;
}

.gallery-heading {
    margin-bottom: 100px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.gallery-heading.appear {
    opacity: 1; /* Make the heading visible */
}

.collage-container {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: 500px; /* Adjust height as needed */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.collage-container.visible {
    opacity: 1; /* Make the content visible */
}

.collage-container.zoom-out .collage-item {
    transform: scale(0.9) translate(0, 0);
}

.collage-item {
    position: absolute;
    transition: transform 1s ease-in-out;
    cursor: pointer;
}

.collage-item img {
    width: 100%;
    height: auto;
    display: block;
}

.collage-item:nth-child(1) {
    top: 0;
    left: 0;
    width: 30%;
}

.collage-item:nth-child(2) {
    top: 10%;
    left: 30%;
    width: 40%;
}

.collage-item:nth-child(3) {
    top: 20%;
    left: 60%;
    width: 30%;
}

.collage-item:nth-child(4) {
    top: 50%;
    left: 10%;
    width: 35%;
}

.collage-item:nth-child(5) {
    top: 60%;
    left: 45%;
    width: 35%;
}

.collage-item:nth-child(6) {
    top: 80%;
    left: 70%;
    width: 25%;
}

.collage-item:nth-child(7) {
    top: 30%;
    left: 75%;
    width: 25%;
}

.gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.gallery-modal img {
    max-width: 80%;
    max-height: 80%;
}

.close, .prev, .next {
    position: absolute;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;
}

.close {
    top: 20px;
    right: 20px;
}

.prev {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.next {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.counter {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.2rem;
}

@media (max-width: 767px) {
    .gallery-heading {
        margin-bottom: 80px;
        font-size: 2.5rem;
        font-weight: bold;
        font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }
}
