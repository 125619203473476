.container-undefined {
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.image-container-undefined {
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: left;
}

.text-container-undefined {
  text-align: center;
  margin-right: 10px;
}

.img-undefined {
  width: 200px;
}

.custom-calendar {
  margin: 20px auto;
}

.react-datepicker__input-container input {
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  font-size: .8rem;
}

.react-datepicker__input-container input::placeholder {
  color: #6c757d;
}

