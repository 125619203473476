.whoever-you-are {
    padding: 50px 20px;
    background-color: #fff;
}

.whoever-heading {
    margin-bottom: 100px;
    font-size: 50px;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000;
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.whoever-heading.appear {
    opacity: 1; /* Make the heading visible */
}

.slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    opacity: 0; /* Initially hide the content */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.slider-container.visible {
    opacity: 1; /* Make the content visible */
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    box-sizing: border-box;
    padding: 20px;
}

.slide.active {
    opacity: 1;
}

.slide-image {
    max-width: 100px; /* Adjust the size of the images as needed */
    /* margin-bottom: 20px; */
}

.slide h3 {
    font-size: 2rem;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
    margin-bottom: 10px;
}

.slide p {
    font-size: 1.2rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
}

.arrow-container {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.slider-arrow {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #007bff;
    margin: 0 5px;
    padding: 10px; /* Add padding for better click area */
    border-radius: 50%; /* Make it circular */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.slider-arrow:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Darken background on hover */
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .slider {
        display: flex;
        flex-wrap: nowrap;
        transform: none;
    }

    .slide {
        min-width: 25%;
        opacity: 1;
    }

    .arrow-container {
        display: none; /* Hide arrows on desktop */
    }
}

@media (max-width: 767px) {
    .whoever-heading {
        margin-bottom: 80px;
        font-size: 2.5rem;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        color: #000;
    }
    .slider {
        display: flex;
        transition: transform 0.5s ease-in-out; /* Make sliding smoother */
    }

    .slide {
        min-width: 85%; /* Make the second section partially visible */
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .slider-arrow {
        display: inline-block;
        position: relative;
        bottom: 10px;
        right: 10px;
    }

    /* Adjust the arrow container position for mobile */
    .arrow-container {
        justify-content: right;
        margin-top: 20px;
    }
}