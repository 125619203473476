/* app.css */

/* Styling for Top-Banner component */
.App-header {
  color: #647ce4; /* Example text color */
  padding: 5vh; /* Example padding */
  top:0; 
  left: 0;
  display: flex;
  width: 100%;
}

.mt-custom {
  margin-top: 7rem; /* Adjust as needed */
}


@media (max-width: 768px) {

  .mt-custom {
    margin-top: 6rem; /* Adjust as needed */
  }  
  
}