/* CustomResidents.css */

.residents-container {
  margin-bottom: 20px;
}

.resident-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.resident-name {
  flex: 1;
  text-align: left;
}

.decrement-button,
.increment-button {
  /* background-color: #a0e4f2; */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.count {
  width: 30px;
  text-align: center;
  font-size: 16px;
}
