.residences-container {
  margin-bottom: 20px;
  position: relative;
}

.residence-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.residence-name {
  flex: 1;
}

.info-button {
  background-color: #7f7f80;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 300px 0px 0px;
  font-size: 14px; /* Adjust font size for smaller button */
}

.decrement-button,
.increment-button {
  /* background-color: #; */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.floor-count {
  width: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.info-box {
  position: absolute;
  top: 50px;
  left: 30%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  color: #000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-width: 40%;
}

.info-content {
  padding: 10px;
}

@media (max-width: 768px) {
  .info-box {
    width: 90%;
    left:50%;
    max-width: none;
  }
  .info-button{
    margin: 0 15px 0px 0px;
  }
  
.info-button {
  width: 20px;
  height: 20px;
  font-size: 10px; /* Adjust font size for smaller button */
}
}