:root {
  --transition-time: 2s;
}

.next-step-section {
  padding: 50px 20px;
  background-color: #ffffff;
  text-align: center;
}

.next-step-heading {
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  opacity: 0; /* Initially hide the heading */
  transition: opacity var(--transition-time) ease-in-out; /* Control the heading appear animation duration here */
}

.next-step-heading.appear {
  opacity: 1; /* Make the heading visible */
}

.next-step-content {
  font-size: 1.5rem;
  text-align: left;
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  color: #333;
  opacity: 0; /* Initially hide the content */
  transition: opacity var(--transition-time) ease-in-out; /* Control the content appear animation duration here */
}

.next-step-content.appear {
  opacity: 1; /* Make the content visible */
}

.next-step-heading .highlight {
  font-size: 50px;
  font-weight: bold;
  font-family: Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline; /* Make "Next Step" a block element for styling */
}

p {
  font-size: 1.5rem;
  font-family: Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: left;
}

@media (max-width: 767px) {
  .next-step-heading .highlight {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    font-family: Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    display: block; /* Make "Next Step" a block element for styling */
  }
}
