/* @media (max-width:600px) {
    canvas {
        width: 95% !important;
    }
} */

canvas {
    background-color: #f9f9f9 !important;
    margin: 0 auto ;
    display: table;
    touch-action: manipulation; /* Allows touch interactions without preventing scrolling */
}


#capture-button {
    /* Your custom styles for the button in this media query */
    width: 120px;
    height: 30px;
    align-items: center;
    left: 5vw;
    border: none;
    font-size: .9em;
    display: block;
    margin: 0 auto;
}

.loader {
    position: absolute;
    top: 55%;
    left: 38%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
  }
  
  @media (max-width: 768px) {
    .loader {
      width: 100px;
      height:100px;
    }
  }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  