.whats-inside-kit {
    padding: 50px 20px;
    position: relative;
}

.kit-heading {
    margin-bottom: 50px;
    font-size: 50px;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.kit-heading.appear {
    opacity: 1; /* Make the heading visible */
}

.kit-description {
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-align: left;
    color: #252525;
    max-width: 900px; /* Add max-width to constrain text width on desktop */
    margin: 0 auto 70px auto; /* Center align the text with top and bottom margin */
    opacity: 0; /* Initially hide the content */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.kit-description.visible {
    opacity: 1; /* Make the content visible */
}

.kit-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: 0; /* Initially hide the content */
    transition: opacity 1s ease-in-out; /* Control the content appear animation duration here */
}

.kit-content.visible {
    opacity: 1; /* Make the content visible */
}

.kit-box {
    position: relative;
    transition: transform 1s ease-in-out; /* Add transition for rotation effect */
}

.box-image {
    width: 500px;
    transition: all 1s ease-in-out;
}

.kit-items {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
}

.kit-items.visible {
    opacity: 1;
}

.kit-item-container {
    width: 100%;
}

.kit-item {
    font-size: 1.5rem;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.chevron-icon {
    transition: transform 0.3s ease-in-out;
}

.chevron-icon.expanded {
    transform: rotate(180deg);
}

.item-details {
    margin-top: 10px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 30px;
    font-size: 1.2rem;
}

/* Mobile styles */
@media (max-width: 767px) {
    .kit-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .kit-description {
        font-size: 1.5rem;
        margin-bottom: 30px;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        text-align: left;
        color: #252525;
        max-width: 100%; /* Ensure it takes full width on mobile */
        padding: 0 10px; /* Add padding to the left and right */
    }

    .kit-heading {
        margin-bottom: 50px;
        font-size: 2.5rem;
        font-weight: bold;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }

    .kit-box {
        align-self: flex-start;
        margin-top: 20px;
        transition: transform .5s ease-in-out; /* Add transition for rotation effect */
    }

    .box-image {
        width: 250px;
        margin-top: 20px;
    }

    .kit-items {
        margin-left: 0;
        align-items: flex-start;
        margin-top: 20px; /* Space between box and items */
    }

    .kit-item {
        text-align: left;
    }
}
