.blog-carousel {
    padding: 0px 20px;
    background-color: hsl(0, 0%, 100%);
}

.blog-carousel h2 {
    margin-bottom: 80px;
    font-size: 50px;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.blog-carousel-heading.appear {
    opacity: 1;
}

.carousel-container {
    position: relative;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 1s ease-in-out 1s;
}

.carousel-container.visible {
    opacity: 1;
}

.carousel-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-slide {
    flex: 0 0 50%; /* Show two slides at a time */
    box-sizing: border-box;
    padding: 20px;
}

.carousel-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.carousel-image-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    background-color: #f0f0f0;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-content {
    text-align: left;
    padding: 20px;
    margin-top: auto;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #007bff;
    z-index: 10;
}

.carousel-arrow.left {
    left: -20px; /* Adjusted to be fully visible */
}

.carousel-arrow.right {
    right: -20px; /* Adjusted to be fully visible */
}

.carousel-arrow:hover {
    color: #0056b3;
}

.carousel-button:hover {
    background-color: #ffffff00;
}

.carousel-content h3 a {
    color: #000000;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 30px;
    text-decoration: none;
}

.carousel-content h3 a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
    .carousel-slide {
        flex: 0 0 100%; /* Show one slide at a time on smaller screens */
    }
    .blog-carousel h2 {
        margin-bottom: 80px;
        font-weight: bold;
        font-size: 2.5rem;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        color: #000;
        opacity: 0;
        transition: opacity 1.5s ease-in-out;
    }
    
    .carousel-arrow.left {
        left: -20px;
    }

    .carousel-arrow.right {
        right: -20px;
    }
}
