.why-us {
    padding: 50px 20px;
    background-color: #fff;
}

.why-us h2 {
    margin-bottom: 100px;
    font-size: 50px;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000;
}
.why-us {
    padding: 50px 20px;
    background-color: #fff;
}

.why-us-heading {
    margin-bottom: 100px;
    font-size: 50px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000;
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.why-us-heading.appear {
    opacity: 1; /* Make the heading visible */
}

.why-us-slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    opacity: 0; /* Initially hide the content */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.why-us-slider-container.visible {
    opacity: 1; /* Make the content visible */
}

.why-us-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.why-us-slide {
    min-width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
}

.why-us-slide.active {
    opacity: 1;
}

.why-us-slide h3 {
    font-size: 1.5rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
    margin-bottom: 10px;
}

.why-us-slide p {
    font-size: 19px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
}

.why-us-info-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    outline: none; /* Remove default outline */
}

.why-us-info-button:focus,
.why-us-info-button:active {
    outline: none; /* Remove focus and active outline */
}

.why-us-info-button:hover {
    background-color: #ffffff;
}

.why-us-plus-icon {
    font-size: 1rem;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    padding: 10px;
}

.why-us-arrow-container {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.why-us-slider-arrow {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #007bff;
    margin: 0 5px;
    padding: 10px; /* Add padding for better click area */
    border-radius: 50%; /* Make it circular */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.why-us-slider-arrow:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Darken background on hover */
}

.why-us-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.why-us-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    position: relative;
}

.why-us-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    outline: none; /* Remove default outline */
}

.why-us-close-button:focus,
.why-us-close-button:active {
    outline: none; /* Remove focus and active outline */
}

.why-us-close-button:hover {
    background-color: #ffffff;
}

.why-us-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .why-us-slider {
        display: flex;
        transition: transform 0.5s ease-in-out; /* Make sliding smoother */
        overflow-x: auto;
        scrollbar-width: none; 
    }

    .why-us-slide {
        min-width: 25%; /* Show multiple slides on desktop */
        opacity: 1;
    }

    .why-us-arrow-container {
        display: flex; /* Show arrows on desktop */
    }

    .why-us h2 {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .why-us-heading {
        margin-bottom: 80px;
        font-size: 2.5rem;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        color: #000;
    }
    .why-us-slider {
        display: flex;
        transition: transform 0.5s ease-in-out; /* Make sliding smoother */
    }

    .why-us-slide {
        min-width: 85%; /* Make the second section partially visible */
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .why-us-slider-arrow {
        display: inline-block;
        position: relative;
        bottom: 10px;
        right: 10px;
    }

    /* Adjust the arrow container position for mobile */
    .why-us-arrow-container {
        justify-content: right;
        margin-top: 20px;
    }
}

.why-us-slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.why-us-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.why-us-slide {
    min-width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
}

.why-us-slide.active {
    opacity: 1;
}

.why-us-slide h3 {
    font-size: 2.0rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
    margin-bottom: 10px;
}

.why-us-slide p {
    font-size: 1.2rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
}

.why-us-info-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    outline: none; /* Remove default outline */
}

.why-us-info-button:focus,
.why-us-info-button:active {
    outline: none; /* Remove focus and active outline */
}

.why-us-info-button:hover {
    background-color: #ffffff;
}

.why-us-plus-icon {
    font-size: 1rem;
    border-radius: 25%;
    background-color: #333;
    color: #fff;
    padding: 10px;
}

.why-us-arrow-container {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.why-us-slider-arrow {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #007bff;
    margin: 0 5px;
    padding: 10px; /* Add padding for better click area */
    border-radius: 50%; /* Make it circular */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.why-us-slider-arrow:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Darken background on hover */
}

.why-us-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.why-us-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 25px;
    width: 90%;
    max-width: 600px;
    position: relative;
}

.why-us-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    outline: none; /* Remove default outline */
}

.why-us-close-button:focus,
.why-us-close-button:active {
    outline: none; /* Remove focus and active outline */
}

.why-us-close-button:hover {
    background-color: #ffffff00;
}

.why-us-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px; /* Adjust as needed for spacing */
    }
    
    .modal-text {
        flex: 1;
        margin-right: 20px; /* Adjust as needed for spacing */
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
    .modal-icon {
        width: 200px; /* Adjust size as needed  */
        height: 200px; /* Adjust size as needed  */
    }
    
    .why-us-modal-content h3 {
        margin: 10px 0 -10px 0; /* Adjust as needed */
        font-size: 2rem; /* Adjust as needed */
    }
    
    .why-us-modal-content p {
        margin: 0;
        font-size: 1rem; /* Adjust as needed */
    }
    .why-us-slider {
        display: flex;
        transition: transform 0.5s ease-in-out; /* Make sliding smoother */
    }

    .why-us-slide {
        min-width: 25%; /* Show multiple slides on desktop */
        opacity: 1;
    }

    .why-us-arrow-container {
        display: flex; /* Show arrows on desktop */
    }

    .why-us h2 {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px; /* Adjust as needed for spacing */
    }
    
    .modal-text {
        flex: 1;
        margin-right: 20px; /* Adjust as needed for spacing */
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
    .modal-icon {
        margin-top: 30px;
        width: 100px; /* Adjust size as needed  */
        height:100px; /* Adjust size as needed  */
    }
    
    .why-us-modal-content h3 {
        margin: 10px 0 -10px 0; /* Adjust as needed */
        font-size: 2rem; /* Adjust as needed */
    }
    
    .why-us-modal-content p {
        margin: 0;
        font-size: 1rem; /* Adjust as needed */
    }
    .why-us h2 {
        margin-bottom: 80px;
        font-size: 2.5rem;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        color: #000;
    }

    .why-us-slider {
        display: flex;
        transition: transform 0.5s ease-in-out; /* Make sliding smoother */
    }

    .why-us-slide {
        min-width: 85%; /* Make the second section partially visible */
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .why-us-slider-arrow {
        display: inline-block;
        position: relative;
        bottom: 10px;
        right: 10px;
    }

    /* Adjust the arrow container position for mobile */
    .why-us-arrow-container {
        justify-content: right;
        margin-top: 20px;
    }
}
