/* Container for the entire story section */
.story {
    display: flex;
    flex-direction: column;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    align-items: center;
    padding: 10px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.story.appear {
    opacity: 1;
    transform: translateY(0);
}

/* Styling for the heading */
.display-5 {
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size:  50px;
    font-weight: bold;
    text-align: center;
}
/* 
.text {
    font-size: 1.5em;
    text-align: justify;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
} */


@media (max-width: 767px) {
    .display-5 {
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size:  2.5rem;
        font-weight: bold;
        text-align: center;
    }
    /* .text {
        font-size: 1.5em;
        text-align: left;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    } */
}
