.testimonials {
    background-color: white;
    padding: 20px;
    overflow: hidden;
}

.testimonials-heading {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 50px;
    opacity: 0;
    /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out;
    /* Control the heading appear animation duration here */
}

.testimonials-heading.appear {
    opacity: 1;
    /* Make the heading visible */
}

.testimonials-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    /* Initially hide the content */
    transition: opacity 1.5s ease-in-out;
    /* Control the content appear animation duration here */
}

.testimonials-container.visible {
    opacity: 1;
    /* Make the content visible */
}

.testimonials-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.testimonial {
    flex: 0 0 100%;
    max-width: 100%;
    opacity: 1;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    /* Ensure a suitable height for mobile */
}

.testimonial-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    /* Add space between image and content */
}

.testimonial-content {
    text-align: center;
    /* Center align text */
    padding: 0 10px;
    /* Ensure content padding */
    box-sizing: border-box;
    /* Ensure padding is included in the element's total width and height */
    margin: 0 10px;
    /* Added margin to prevent text cutoff */
    word-wrap: break-word;
    /* Ensure long words break to avoid overflow */
}

.testimonial-content h3 {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}

.testimonial-content h4 {
    margin: 5px 0;
    font-size: 1.5rem;
    color: #666;
}

.testimonial-content p {
    margin: 10px 0 0;
    font-size: 1.2rem;
}

.dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    opacity: 0;
    /* Initially hide the content */
    transition: opacity 1.5s ease-in-out;
    /* Control the content appear animation duration here */
}

.dots.visible {
    opacity: 1;
    /* Make the content visible */
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .testimonial {
        padding: 40px;
        flex-direction: column;
        /* Arrange image and content vertically */
    }

    .testimonial-content {
        padding-left: 0;
        text-align: center;
        /* Center align text for larger screens */
    }

    .testimonial-image {
        width: 100px;
        height: 100px;
        margin: 0 0 20px 0;
        /* Center image and add space between image and content */
    }
}

@media (max-width: 767px) {
    .testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: auto; /* Adjust to auto for height */
    }

    .testimonial-content {
        width: 100%; /* Ensure full width for the content on mobile */
        max-width: calc(50% - 10px); /* Ensure the content fits within the viewport minus padding */
        padding: 0; /* Remove padding to prevent cutting off text */
    }

    /* Ensure the paragraph text wraps and is fully readable */
    .testimonial-content p {
        margin: 10px 0 0;
        font-size: 1.2rem;
        word-wrap: break-word; /* Ensure long words break to avoid overflow */
        white-space: normal; /* Ensure the text wraps to multiple lines */
        display: block; /* Ensure block display for wrapping */
    }
}
