.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    transition: background-color 0.3s, box-shadow 0.3s, padding 0.3s;
    z-index: 1000;
    box-sizing: border-box;
  }
  
  
  
  .header.scrolled {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.025);
    padding: 5px 20px; /* Adjust padding if needed */
  }
  
  .logo img {
    height: 75px;
    margin-left: 85px;
    transition: height 0.3s; /* Smooth transition */
  }
  
  
  .header.scrolled .logo img {
    height: 35px; /* Resize the logo when scrolled */
  }
  
  .nav {
    display: flex;
    gap: 15px;
  }
  
  .nav-link {
    margin-top: -10px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: #000000;
    font-size: 1.5rem;
  }
  
  .nav-link:hover {
    color: #000000;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: right 0.3s ease-in-out;
    z-index: 1001;
  }
  
  .sidebar.open {
    right: 0;
  }
  
  .sidebar .close-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .sidebar .nav-link {
    margin: 20px 0;
    font-size: 1.2rem;
    color: #030303;
    text-decoration: none;
  }
  
  .sidebar .nav-link:hover {
    color: #0056b3;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .overlay.open {
    opacity: 1;
    visibility: visible;
  }
  
  @media (max-width: 768px) {
    .sidebar .nav-link {
      margin: 20px 0;
      font-size: 1.5rem;
      color: #030303;
      text-decoration: none;
    }
    .logo img {
      height: 35px;
      margin-left: 0px;
      transition: height 0.3s; /* Smooth transition */
    }
    .nav {
      display: none;
    }
  
    .hamburger {
      display: flex;
    }
  }
  
  .content {
    margin-top: 100px; /* Adjust this to your header height */
  }