/* App.css */

@keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25%, 75% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
  }
  
  .card-start {
    animation: slideIn 0.5s ease-out;
  }
  
  .card-exit {
    animation: slideOut 0.5s ease-out;
  }
  
  .invalid {
    border-bottom-color: red;
    animation: shake 0.5s;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  .form-check-input{
    width: 1em;
    height: 1em;
    padding: 0px;
  }
  
  /* .form-check-input:checked{
    background-color: #b50030;
    border-color: #ff0049;
  } 
   */
  
  /* Other existing CSS */
  
  .container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .container-first {
    background: #e9ecef3d;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.47);
    width: 90%; /* Use 90% of the width to avoid touching the edges */
    max-width: 1200px; /* Ensure it doesn't go beyond a certain max width */
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    margin: 100px auto; /* Center align and add vertical margin */
  }
  
  /* .container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  } */

  .form-heading{
    display: block;
    text-align: center;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: inline;
    margin-bottom: 5px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
  }
  
  .form-group input
  .form-group select {
    width: 100%;
    padding: 10px 0; /* Changed padding */
    border: none;
    border-bottom: 1px solid #ccc; /* Single line at bottom */
    box-sizing: border-box;
    font-size: 14px;
    background-color: transparent; /* Removed background */
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-bottom-color: #007bff; /* Change color on focus */
    outline: none;
  }
  
  .form-group select {
    -webkit-appearance: none; /* Remove default dropdown arrow */
    -moz-appearance: none;
    appearance: none;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    background-color: #ff0049;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #b50030;
  }
  
  .button-next {
    margin-left: auto;
  }
  
  .progress-bar {
    background-color: #e9ecef;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    height: 20px;
    position: relative;
  }
  
  .progress-bar div {
    height: 100%;
    background-color: #ff0049;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
  
  .progress-percentage {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #007bff;
  }
  
  .custom-dropdown .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #ccc;
    background: transparent;
    cursor: pointer;
  }
  
  .custom-dropdown .dropdown-list {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    background: white;
    z-index: 10;
    position: absolute;
    width: 100%;
  }
  
  .custom-dropdown .dropdown-list-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .custom-dropdown .dropdown-list-item:hover {
    background: #B50030;
    color: white;
  }
  
  .residents-container,
  .residences-container {
    margin-bottom: 20px;
  }
  
  .resident-row,
  .residence-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .resident-name,
  .residence-name {
    flex: 1;
  }
  
  .decrement-button,
  .increment-button {
    background-color: #7f7f80;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .decrement-button:hover,
  .increment-button:hover {
    background-color: #b50030;
  }
  
  .count,
  .floor-count {
    width: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }
  