.floor-plan-demo {
    padding: 50px 20px;
    position: relative;
    overflow: hidden;
}

.floor-plan-heading {
    margin-bottom: 80px;
    font-size: 50px;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    z-index: 1;
    position: relative;
    color: rgb(0, 0, 0);
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.floor-plan-heading.appear {
    opacity: 1; /* Make the heading visible */
}

.floor-plan-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0; /* Initially hide the content */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.floor-plan-content.visible {
    opacity: 1; /* Make the content visible */
}

.text-content {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    z-index: 1;
    color: rgb(0, 0, 0);
    padding: 0 20px;
}

.video-container {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .floor-plan-content {
        margin-top: -40px;
        flex-direction: row;
        justify-content: center;
    }

    .text-content {
        margin-right: 20px;
        margin-bottom: 0;
        max-width: 400px;
    }

    .video-container {
        height: 500px;
    }
}

@media (max-width: 767px) {
    .floor-plan-heading {
        font-size: 2.5rem;
        font-weight: bold;
    }

    .video-container {
        height: 300px;
    }
}
