:root {
  --transition-time: 2s;
}

.expert-visit-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #ffffff;
}

.expert-visit-heading {
  font-size: 50px; /* Increase the font size for all devices */
  font-weight: bold;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-bottom: 20px; /* Adjust as needed */
  opacity: 0; /* Initially hide the heading */
  transition: opacity var(--transition-time) ease-in-out; /* Control the heading appear animation duration here */
}

.expert-visit-heading.appear {
  opacity: 1; /* Make the heading visible */
}

.expert-visit-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: 0; /* Initially hide the content */
  transition: opacity var(--transition-time) ease-in-out; /* Control the content appear animation duration here */
}

.expert-visit-content.appear {
  opacity: 1; /* Make the content visible */
}

.expert-visit-text {
  flex: 1;
  font-size: 1.2rem;
  padding: 20px;
  max-width: 40%;
  box-sizing: border-box;
  margin-right: 20px; /* Space between text and paper */
}

p {
  font-size: 1.5rem;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.epert-video-container {
  flex: 1;
  position: relative;
  width: 100%;
}

.expert-video {
  width: 75%;
  height: auto;
  transition: opacity var(--transition-time) ease-in-out;
}

/* Mobile Layout */
@media (max-width: 768px) {
  .expert-visit-section {
    flex-direction: column;
    align-items: center;
  }

  .expert-visit-content {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
    margin-bottom: 20px;
    text-align: left; /* Align text to the left on mobile */
  }

  .expert-visit-text {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px; /* Space between text and paper */
  }

  .expert-video-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .expert-video {
    width: 100%;
    height: auto;
    transition: opacity var(--transition-time) ease-in-out;
  }

  .expert-visit-heading {
    font-size: 2.5rem; /* Increase the font size for mobile */
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bold;
    display: block;
  }

  .mobile-text {
    display: block;
    margin-top: 20px;
  }

  .desktop-text {
    display: none;
  }
}

@media (min-width: 769px) {
  .mobile-text {
    display: none;
  }
}