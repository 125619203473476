.Planbutton-1 {
  left: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.column {
  margin: 0 10px;
}

.dropdown-img {
  width: 120%;
  padding: 5px;
  background-color: #f8f9fa;
  border: 1px solid #67a9d4;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, border-color 0.3s; /* Add transition */
}

.dropdown-img:hover {
  background-color: #67a9d4;
  border-color: #67a9d4;
}
