.how-it-works {
    padding: 20px 10px;
}

.how-it-works h2 {
    margin-bottom: 90px;
    font-size: 2.5rem;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 0; /* Initially hide the heading */
    transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.how-it-works h2.appear {
    opacity: 1; /* Make the heading visible */
}

.steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    opacity: 0; /* Initially hide the content */
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.steps-container.visible {
    opacity: 1; /* Make the content visible */
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    position: relative;
    opacity: 0; /* Initially hide the steps */
}

.step-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid #108200;
    transform: scale(0); /* Initially scale to 0 for pop effect */
    opacity: 0; /* Initially hide the icon */
}

.step p {
    margin-top: 10px;
    font-size: 1.5rem;
    margin-left: 15px; /* Added margin for mobile */
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.line {
    position: absolute;
    border: 0;
    border-top: 2px dashed #108200;
    opacity: 0; 
}

.pop .step-icon {
    animation: pop 0.25s forwards; /* Control the pop animation duration here */
}

.pop {
    opacity: 1; /* Make the step visible */
}

.draw {
    animation: draw .5s forwards; /* Control the draw animation duration here */
}

@keyframes pop {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes draw {
    0% {
        width: 0;
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

/* Mobile styles */
.line1 {
    width: 165px; /* This will be the final width after the draw animation */
    top: 95%;
    left: 67%;
    transform-origin: 0% 50%;
    transform: rotate(153deg);
}

.line2 {
    width: 165px; /* This will be the final width after the draw animation */
    top: 108%;
    right: 68%;
    transform-origin: 100% 50%;
    transform: rotate(211deg);
}

.line3 {
    width: 165px; /* This will be the final width after the draw animation */
    top: 91%;
    left: 68%;
    transform-origin: 0% 50%;
    transform: rotate(153deg);
}

.step:last-child .line {
    display: none;
}

/* For desktop */
@media (min-width: 768px) {
    .steps-container {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .step {
        margin: 0 30px;
    }

    .step:nth-child(even) {
        transform: translateX(0) translateY(-50px); /* Reset translateX and set translateY for desktop */
    }

    .step:nth-child(odd) {
        transform: translateX(0) translateY(50px); /* Reset translateX and set translateY for desktop */
    }

    .line1 {
        width: 180px; /* This will be the final width after the draw animation */
        top: 12%;
        left: 77%;
        transform-origin: 0% 50%;
        transform: rotate(339deg)
    }

    .line2 {
        width: 224px; /* This will be the final width after the draw animation */
        top: 26%;
        right: 18%;
        transform-origin: 100% 50%;
        transform: rotate(202deg);
    }

    .line3 {
        width: 260px; /* This will be the final width after the draw animation */
        top: 18%;
        left: 70%;
        transform-origin: 0% 50%;
        transform: rotate(341deg);
    }
    .how-it-works h2 {
        margin-bottom: 130px;
        font-size: 50px;
        font-weight: bold;
        margin-left : 10px;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
}

/* Mobile styles */
@media (max-width: 767px) {
    .step {
        margin: 40px 0px; /* Slightly reduce the gap between icons */
        flex-direction: row; /* Set flex direction to row for mobile */
        justify-content: space-between; /* Allow space between elements */
    }

    .step-icon {
        width: 80px;
        height: 80px;
    }

    .step p {
        margin: 0 0 0 15px; /* Margin to the left for mobile */
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }

    /* Individual positioning for odd and even steps */
    .step:nth-child(odd) .step-icon {
        order: 2; /* Place icon on the right for odd steps */
    }

    .step:nth-child(odd) p {
        order: 1; /* Place text on the left for odd steps */
        text-align: left; /* Align text to the right */
    }

    .step:nth-child(even) .step-icon {
        order: 1; /* Place icon on the left for even steps */
    }

    .step:nth-child(even) p {
        order: 2; /* Place text on the right for even steps */
        text-align: right; /* Align text to the left */
    }
}
