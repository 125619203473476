.render-video-wrapper {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.render-heading {
  font-size: 50px;
  font-weight: bold;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #000000;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 50px;
  opacity: 0; /* Initially hide the heading */
  transition: opacity 1.5s ease-in-out; /* Control the heading appear animation duration here */
}

.render-heading.appear {
  opacity: 1; /* Make the heading visible */
}

.render-kit-description {
  font-size: 1.5rem;
  margin-bottom: 30px;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: left;
  color: #252525;
  max-width: 900px; /* Add max-width to constrain text width on desktop */
  margin: 0 auto 70px auto; /* Center align the text with top and bottom margin */
  opacity: 0; /* Initially hide the content */
  transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.render-kit-description.visible {
  opacity: 1; /* Make the content visible */
}

.render-video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  opacity: 0; /* Initially hide the content */
  transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
}

.render-video-container.visible {
  opacity: 1; /* Make the content visible */
}

.render-background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 0;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .render-background-video {
    height: auto; /* Auto height for 16:9 aspect ratio */
  }
}

@media (max-width: 767px) {
  .render-heading {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
    text-align: left;
    margin-bottom: 20px;
  }
  .render-background-video {
    width: auto; /* Auto width for 9:16 aspect ratio */
    height: 100%;
  }
  .render-kit-description {
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-align: left;
    color: #252525;
    max-width: 900px; /* Add max-width to constrain text width on desktop */
    margin:  auto 30px auto; /* Center align the text with top and bottom margin */
    opacity: 0; /* Initially hide the content */
    padding-bottom: 30px;
    transition: opacity 1.5s ease-in-out; /* Control the content appear animation duration here */
  }
  
  .render-heading {
    font-size: 2.5rem;
  }
}
