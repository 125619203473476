.more-about-your-home-section {
    background-color: white;
    margin-top: 100px;
    position: relative;
    width: 100%;
    height: 500px; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .section-heading {
    position: absolute;
    top: -70px;
    width: 100%;
    font-family:  Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 50px; /* Font size for desktop */
}

  
  /* Main Image Container */
  .main-image-container {
    position: absolute;
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    top: 50%; /* Adjust position as needed */
    left: 50%; /* Adjust position as needed */
    transform: translate(-50%, -50%); /* Center the image */
    transition: transform 2s ease-in-out;
  }
  
  .main-image-container.rotate {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  
  .main-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  /* Quadrant Containers */
  .quadrant {
    position: absolute;
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    opacity: 0;
    transition: opacity 2s ease-in-out, transform 2s ease-in-out;
  }
  
  /* Quadrant 1 */
  .quadrant1 {
    width: 80px;
    top: 10%; /* Initial position */
    left: 10%; /* Initial position */
    --final-top: 10%; /* Final position - adjust as needed */
    --final-left: 10%; /* Final position - adjust as needed */
    transform: translate(-50%, -50%);
  }
  
  /* Quadrant 2 */
  .quadrant2 {
    width: 80px;
    top: 10%; /* Initial position */
    right: 10%; /* Initial position */
    --final-top: 10%; /* Final position - adjust as needed */
    --final-right: 10%; /* Final position - adjust as needed */
    transform: translate(50%, -50%);
  }
  
  /* Quadrant 3 */
  .quadrant3 {
    width: 80px;
    bottom: 10%; /* Initial position */
    left: 10%; /* Initial position */
    --final-bottom: 10%; /* Final position - adjust as needed */
    --final-left: 10%; /* Final position - adjust as needed */
    transform: translate(-50%, 50%);
  }
  
  /* Quadrant 4 */
  .quadrant4 {
    width: 80px;
    bottom: 10%; /* Initial position */
    right: 10%; /* Initial position */
    --final-bottom: 10%; /* Final position - adjust as needed */
    --final-right: 10%; /* Final position - adjust as needed */
    transform: translate(50%, 50%);
  }
  
  .quadrant.appear {
    opacity: 1;
    transform: translate(0, 0);
    top: var(--final-top); /* Final position */
    left: var(--final-left); /* Final position */
    right: var(--final-right); /* Final position */
    bottom: var(--final-bottom); /* Final position */
  }
  
  .sub-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  

  /* Mobile Layout */
  @media (max-width: 768px) {
    .section-heading {
      font-size: 2.5rem; /* Font size for mobile */
      font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: bold;
      text-align: center;
      margin-top: -50px;
   }
    .main-image-container {
      width: 350px; /* Adjust size as needed for mobile */
      height:350px; /* Adjust size as needed for mobile */
    }
  
    .quadrant {
      width: 60px; /* Adjust size as needed for mobile */
      height: 60px; /* Adjust size as needed for mobile */
    }
  
    .quadrant1-text {
        text-align: center;
        margin-top: 10px;
        width: 160px;
        font-size: 12px;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        position: absolute;
        top: -245%; /* Adjust position as needed */
        left: 0%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }    
      .quadrant2-text {
        text-align: center;
        margin-top: 10px;
        width: 100px;
        font-size: 12px;
        position: absolute;
        top: -239%; /* Adjust position as needed */
        left: 100%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
      .quadrant3-text {
        text-align: center;
        margin-top: 10px;
        width: 118px;
        font-size: 12px;
        position: absolute;
        top: 286%; /* Adjust position as needed */
        left: 0%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
      .quadrant4-text {
        text-align: center;
        margin-top: 10px;
        width: 100px;
        font-size: 12px;
        position: absolute;
        top: 230%; /* Adjust position as needed */
        left: 100%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
      

    /* Control Positions for Mobile */
    .quadrant1 {
      width:130px;
      top: 15%; /* Initial position */
      left: 15%; /* Initial position */
      --final-top: 32%; /* Final position */
      --final-left: 20%; /* Final position */
    }
  
    .quadrant2 {
      width:110px;
      top: 15%; /* Initial position */
      right: 15%; /* Initial position */
      --final-top: 31%; /* Final position */
      --final-right: 22%; /* Final position */
    }
  
    .quadrant3 {
      width:135px;
      bottom: 15%; /* Initial position */
      left: 15%; /* Initial position */
      --final-bottom: 44%; /* Final position */
      --final-left: 21%; /* Final position */
    }
  
    .quadrant4 {
      width:100px;
      bottom: 15%; /* Initial position */
      right: 15%; /* Initial position */
      --final-bottom: 38%; /* Final position */
      --final-right: 22%; /* Final position */
    }
  }
  
  /* Desktop Layout */
  @media (min-width: 769px) {
    .main-image-container {
      width: 400px; /* Adjust size as needed for desktop */
      height: 400px; /* Adjust size as needed for desktop */
    }
  
    .quadrant {
      width: 80px; /* Adjust size as needed for desktop */
      height: 80px; /* Adjust size as needed for desktop */
    }
    .quadrant1-text {
        text-align: center;
        font-size: 1.2rem;
        font-family:  Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        margin-top: 10px;
        width: 350px;
        position: absolute;
        top: -60%; /* Adjust position as needed */
        left: -180%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
 
      .quadrant2-text {
        text-align: center;
        width: 350px;
        font-size: 1.2rem;
        font-family:  Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;        margin-top: 10px;
        position: absolute;
        top: -54%; /* Adjust position as needed */
        left: 318%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
      .quadrant3-text {
        text-align: center;
        width:350px;
        font-size: 1.2rem;
        font-family:  Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;        margin-top: 10px;
        position: absolute;
        top: 100%; /* Adjust position as needed */
        left: -190%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
      .quadrant4-text {
        text-align: center;
        margin-top: 10px;
        font-size: 1.2rem;
        font-family:  Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;        width: 350px;
        position: absolute;
        top: 38%; /* Adjust position as needed */
        left: 380%; /* Adjust position as needed */
        transform: translate(-50%, 0); /* Center the text */
      }
      
  
  
    /* Control Positions for Desktop */
    .quadrant1 {
      width: 150px;
      top: 10%; /* Initial position */
      left: 10%; /* Initial position */
      --final-top: 27%; /* Final position */
      --final-left: 42%; /* Final position */
    }
  
    .quadrant2 {
      width: 120px;
      top: 10%; /* Initial position */
      right: 10%; /* Initial position */
      --final-top: 26%; /* Final position */
      --final-right: 42%; /* Final position */
    }
  
    .quadrant3 {
      width: 145px;
      bottom: 10%; /* Initial position */
      left: 10%; /* Initial position */
      --final-bottom: 40%; /* Final position */
      --final-left: 42%; /* Final position */
    }
  
    .quadrant4 {
      width: 95px;
      bottom: 10%; /* Initial position */
      right: 10%; /* Initial position */
      --final-bottom: 30%; /* Final position */
      --final-right: 42%; /* Final position */
    }
  }
  